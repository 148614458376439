import React, { useEffect, useState } from 'react'

import styles from './title.module.scss'

const Title = ({ title, itemDisplay, imageCount }) => {
  const [titleCoverClasses, setTitleCoverClasses] = useState(styles.cover)
  const [titleDivClasses, setTitleDivClasses] = useState(styles.title + ' ' + styles.hide)

  useEffect(() => {
    setTimeout(() => {
      setTitleCoverClasses(styles.cover + ' ' + styles.show)
      setTimeout(() => {
        setTitleCoverClasses(styles.cover + ' ' + styles.show + ' ' + styles.hide)
        setTimeout(() => {
          setTitleCoverClasses(styles.cover)
        }, 500)
      }, 500)
    }, 500)
  }, [imageCount, itemDisplay, title])

  useEffect(() => {
    setTitleDivClasses(styles.title)
    setTimeout(() => {
      setTitleDivClasses(styles.title + ' ' + styles.show)
    }, 1000)
  }, [imageCount, itemDisplay, title])

  return (
    <div className={styles.titleContainer + ' ' + `${itemDisplay === 'Image(s) Left and Text Right' && imageCount === 1 ? styles.titleImageRight : itemDisplay === 'Image(s) Left and Text Right' && imageCount > 1 ? styles.titleImagesRight : itemDisplay === 'Text Left and Image(s) Right' && imageCount === 1 ? styles.titleImageLeft : itemDisplay === 'Text Left and Image(s) Right' && imageCount > 1 ? styles.titleImagesLeft : styles.titleWide}`}>
      <h1 className={titleDivClasses}>
        {title}
      </h1>
      <div className={titleCoverClasses} />
    </div>
  )
}

export default Title
