/* Packages */
import React, { useContext, useState, useEffect } from 'react'
/* Imported Components */
import { Context } from '../../../context'
import Title from './title/title'
import Images from './images/images'
import Heading from './heading/heading'
import Text from './text/text'
import Category from './category/category'
/* Styles */
import styles from './content.module.scss'
/* Component */
const Content = ({
  decade,
  setFilterText,
  milestone = {
    category: '',
    title: '',
    headlineLeft: '',
    headlineRight: '',
    textLeft: { textLeft: '' },
    textRight: { textRight: '' },
    itemDisplay: '',
    images: []
  },
  historyItems
}) => {
  const { windowSize } = useContext(Context)
  const [listHeight, setListHeight] = useState(0)
  useEffect(() => {
    if (windowSize.height) {
      const header = document.getElementsByTagName('header')[0]
      const timelineSelector = document.getElementById('timeline-selector')
      header && timelineSelector && setListHeight(window.innerHeight - (header.offsetHeight + timelineSelector.offsetHeight))
    }
  }, [windowSize])

  useEffect(() => {
  }, [listHeight])
  return windowSize.device === 'phone' ? (
    <div className={styles.list} style={{ height: listHeight }}>
      {historyItems && historyItems[decade] && historyItems[decade].milestones && historyItems[decade].milestones.map((milestone, i) => (
        <div key={i} className={styles.content}>
          <Images images={milestone.images} title={milestone.title} />
          <div className={styles.textContainer + ` ${milestone.images && milestone.images.length > 1 ? styles.textLeftImages : styles.textLeftImage}`}>
            <Heading heading={milestone.headlineLeft} />
            <Text text={(milestone.textLeft && milestone.textLeft.textLeft) || milestone.textRight.textRight} />
            <Category category={milestone.category} setFilterText={setFilterText} />
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className={styles.content}>
      <Title title={milestone.title} itemDisplay={milestone.itemDisplay} imageCount={milestone.images ? milestone.images.length : 0} />
      {milestone.itemDisplay === 'Text Left and Image(s) Right' && (
        <>
          <div className={styles.textContainer + ` ${milestone.images && milestone.images.length > 1 ? styles.textLeftImages : styles.textLeftImage}`}>
            <Heading heading={milestone.headlineLeft} />
            <Text text={milestone.textLeft.textLeft} />
            <Category category={milestone.category} setFilterText={setFilterText} />
          </div>
          <Images side='right' images={milestone.images} />
        </>
      )}
      {milestone.itemDisplay === 'Image(s) Left and Text Right' && (
        <>
          <Images side='left' images={milestone.images} />
          <div className={styles.textContainer + ` ${milestone.images && milestone.images.length > 1 ? styles.textRightImages : styles.textRightImage}`}>
            <Heading heading={milestone.headlineRight} />
            <Text text={milestone.textRight.textRight} />
            <Category category={milestone.category} setFilterText={setFilterText} />
          </div>
        </>
      )}
      {milestone.itemDisplay === 'Text only' && (
        <div className={styles.textContainer + ' ' + styles.textFull}>
          <Heading heading={milestone.headlineLeft} />
          <Text text={milestone.textLeft.textLeft} />
          <Category category={milestone.category} setFilterText={setFilterText} />
        </div>
      )}
    </div>
  )
}
/* Exports */
export default Content
