import React, { useEffect, useState } from 'react'

import styles from './heading.module.scss'

const Heading = ({ heading }) => {
  const [headingClasses, setHeadingClasses] = useState(styles.heading)
  useEffect(() => {
    setTimeout(() => {
      setHeadingClasses(styles.heading + ' ' + styles.show)
    }, 0)
  }, [heading])

  return (
    <h2 className={headingClasses}>{heading}</h2>
  )
}

export default Heading
