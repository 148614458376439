import React, { useEffect, useState } from 'react'

import styles from './text.module.scss'

const Text = ({ text }) => {
  const [textClasses, setTextClasses] = useState(styles.text)
  useEffect(() => {
    setTimeout(() => {
      setTextClasses(styles.text + ' ' + styles.show)
    }, 250)
  }, [text])

  return (
    <p className={textClasses}>{text}</p>
  )
}

export default Text
