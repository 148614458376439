import React, { useContext, useEffect, useState } from 'react'

import { Context } from '../../../../context'

import styles from './images.module.scss'

const Images = ({ side, images, title }) => {
  const { arrowLeft, arrowRight, windowSize: { device } } = useContext(Context)
  const [activeImage, setActiveImage] = useState(0)
  const [imageCoverClasses, setImageCoverClasses] = useState(styles.cover)
  const [imagesClasses, setImagesClasses] = useState(styles.images + ' ' + styles.hide)
  const [imageControlsClasses, setImageControlsClasses] = useState(styles.imageControls + ' ' + styles.hide)
  const [titleDivClasses, setTitleDivClasses] = useState(styles.title)

  useEffect(() => {
    setTimeout(() => {
      setImageCoverClasses(styles.cover + ' ' + styles.show)
      setTimeout(() => {
        setImageCoverClasses(styles.cover + ' ' + styles.show + ' ' + styles.hide)
        setTimeout(() => {
          setImageCoverClasses(styles.cover)
        }, 500)
      }, 500)
    }, 500)
    return () => {
      setImageCoverClasses(styles.cover)
    }
  }, [images])

  useEffect(() => {
    setImagesClasses(styles.images + ' ' + styles.hide)
    setImageControlsClasses(styles.imageControls + ' ' + styles.hide)
    setTimeout(() => {
      setImagesClasses(styles.images)
      setImageControlsClasses(styles.imageControls)
    }, 1000)
    return () => {
      setImagesClasses(styles.images)
      setImageControlsClasses(styles.imageControls)
    }
  }, [images])

  useEffect(() => {
    setTitleDivClasses(styles.title)
    setTimeout(() => {
      setTitleDivClasses(styles.title + ' ' + styles.show)
    }, 1000)
  }, [title])

  const GalleryControls = ({ images }) => {
    const previousImage = () => setActiveImage(activeImage - 1)
    const nextImage = () => setActiveImage(activeImage + 1)

    const GallerySquare = ({ id, images }) => {
      const setActiveImageId = () => {
        setActiveImage(id)
      }

      return (
        <div className={id === activeImage ? `${styles.square} ${styles.active}` : styles.square} onClick={setActiveImageId} />
      )
    }

    return (
      <div className={imageControlsClasses}>
        <div className={activeImage === 0 ? `${styles.arrowLeft} ${styles.disabled}` : styles.arrowLeft} onClick={activeImage !== 0 ? previousImage : null}>
          <img src={arrowLeft} alt='previous' />
        </div>
        <div className={styles.squares}>
          {images && images.map(({ active }, i) => <GallerySquare key={i} id={i} />)}
        </div>
        <div className={activeImage === images.length - 1 ? `${styles.arrowRight} ${styles.disabled}` : styles.arrowRight} onClick={activeImage !== images.length - 1 ? nextImage : null}>
          <img src={arrowRight} alt='next' />
        </div>
      </div>
    )
  }

  const className = !images ? styles.titleOnly : images.length > 1 ? side === 'left' ? styles.imagesLeft : styles.imagesRight : side === 'left' ? styles.imageLeft : styles.imageRight
  return (
    <div className={className}>
      <div className={imagesClasses}>
        {device === 'phone' && (
          <div className={styles.titleContainer}>
            <h1>
              {title}
            </h1>
          </div>
        )}
        {images && images.length && images.map((image, i) => {
          return (
            <img key={image.title} className={i === activeImage ? `${styles.image} ${styles.active}` : styles.image} src={image.file.url} alt={image.title} />
          )
        })}
      </div>
      {images && images.length && images.length > 1 && <GalleryControls images={images} />}
      <div id='image-cover' className={imageCoverClasses} />
    </div>
  )
}

export default Images
