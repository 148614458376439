/* Packages */
import React, { useContext, useEffect, useState } from 'react'
/* Imported Components */
import { Context } from '../../../context'
import Content from '../content/content'
/* Styles */
import styles from './timeline.module.scss'
/* Component */
const Timeline = ({ decade = 0, setDecade }) => {
  const { history, menuArrow, windowSize: { device } } = useContext(Context)
  const [historyItems, setHistoryItems] = useState([])
  const [activeMilestoneId, setActiveMilestoneId] = useState()
  const [activeMilestone, setActiveMilestone] = useState()
  const [gridTemplateStyle, setGridTemplateStyle] = useState({
    gridTemplateColumns: '1fr auto auto auto auto auto auto auto auto auto auto'
  })
  const [filterText, setFilterText] = useState('')
  const [categories, setCategories] = useState(['All Categories'])
  const [menuOpen, setMenuOpen] = useState(false)
  const [decadeMenuOpen, setDecadeMenuOpen] = useState(false)
  const [coverClasses, setCoverClasses] = useState(styles.cover)
  const [menuClasses, setMenuClasses] = useState(styles.menu)
  const [decadeCoverClasses, setDecadeCoverClasses] = useState(styles.cover)
  const [decadeMenuClasses, setDecadeMenuClasses] = useState(styles.decademenu)

  useEffect(() => {
    if (history.length) {
      if (filterText) {
        const decades = []
        history.forEach(decade => {
          decades.push({
            ...decade,
            milestones: decade.milestones.filter(milestone => milestone.category === filterText)
          })
        })
        setHistoryItems([...decades])
      } else {
        setHistoryItems([...history])
      }
    }
  }, [history, filterText])

  useEffect(() => {
    if (history.length) {
      const categories = []
      history.forEach(decade => {
        decade.milestones.forEach(milestone => {
          if (!categories.find(category => category === milestone.category)) {
            categories.push(milestone.category)
          }
        })
      })

      setCategories(['All Categories', ...categories.sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1)])
    }
  }, [history])

  const toggleMenu = () => setMenuOpen(!menuOpen)
  const toggleDecadeMenu = () => setDecadeMenuOpen(!decadeMenuOpen)

  useEffect(() => {
    if (menuOpen) {
      setMenuClasses(styles.menu + ' ' + styles.active)
      setCoverClasses(styles.cover + ' ' + styles.show)
    } else {
      setMenuClasses(styles.menu)
      setCoverClasses(styles.cover)
    }
  }, [menuOpen])

  useEffect(() => {
    if (decadeMenuOpen) {
      setDecadeMenuClasses(styles.decadeMenu + ' ' + styles.active)
      setDecadeCoverClasses(styles.cover + ' ' + styles.show)
    } else {
      setDecadeMenuClasses(styles.decadeMenu)
      setDecadeCoverClasses(styles.cover)
    }
  }, [decadeMenuOpen])

  useEffect(() => {
    if (decade !== undefined && historyItems.length) {
      if (history[decade] && historyItems[decade].milestones.length) {
        setActiveMilestone(historyItems[decade].milestones[0])
        setActiveMilestoneId(0)
      } else {
        setDecade(historyItems.findIndex(decade => decade.milestones.length))
      }
      const grid = []
      history.forEach(decade => grid.push('auto'))
      grid[decade] = '1fr'
      setGridTemplateStyle({ gridTemplateColumns: grid.join(' ') })
    }
  }, [decade, historyItems])

  const Decade = ({ decadeObject: { title, milestones: list }, id }) => {
    const setActiveDecade = () => setDecade(id)

    return (
      <div onClick={list.length ? setActiveDecade : null} className={decade === id ? `${styles.decade} ${styles.active}` : styles.decade}>
        <p className={list.length ? styles.title : `${styles.title} ${styles.empty}`}>{title}</p>
        <div className={decade === id ? `${styles.milestones} ${styles.active}` : `${styles.milestones}`}>
          {list.map((milestone, i) => <Milestone key={i} parent={id} id={i} milestone={milestone} />)}
        </div>
      </div>
    )
  }

  const Milestone = ({ milestone, parent, id }) => {
    const setMilestone = () => {
      setActiveMilestoneId(id)
      setActiveMilestone(milestone)
    }

    return (
      <div id={id} className={parent === decade && id === activeMilestoneId ? `${styles.milestone} ${styles.active}` : styles.milestone} onClick={setMilestone} />
    )
  }

  const MenuItems = ({ category }) => {
    const filterMilestones = () => setFilterText(category === 'All Categories' ? '' : category)

    return <div className={styles.menuItem} onClick={filterMilestones}>{category.toUpperCase()}</div>
  }

  const DecadeMenuItems = ({ decadeObject }) => {
    const changeDecade = () => setDecade(historyItems.findIndex(decade => decade.title === decadeObject.title))
    return <div className={styles.menuItem} onClick={changeDecade}>{decadeObject.title}</div>
  }

  return (
    <section className={styles.timeline}>
      <div className={coverClasses} onClick={toggleMenu} />
      <div className={decadeCoverClasses} onClick={toggleDecadeMenu} />
      <Content setFilterText={setFilterText} milestone={activeMilestone} historyItems={historyItems} decade={decade} />
      <div id='timeline-selector' className={styles.selector}>
        <div className={styles.decadeContainer} style={gridTemplateStyle}>
          {historyItems.map((decadeObject, i) => <Decade key={i} decadeObject={decadeObject} id={i} />)}
          {device === 'phone' && (
            <div className={styles.decadeButton} onClick={toggleDecadeMenu}>
              <strong className={styles.title}>{historyItems && historyItems[decade] && historyItems[decade].title}</strong>
              <img className={styles.menuArrow} src={menuArrow} alt='Open menu' />
              <div className={decadeMenuClasses}>
                {historyItems.filter(decade => decade.milestones && decade.milestones.length).map((decadeObject, i) => (
                  <DecadeMenuItems key={i} decadeObject={decadeObject} />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={styles.categories} onClick={toggleMenu}>
          <strong>{device === 'phone' ? 'CATEGORIES' : filterText ? filterText.toUpperCase() : 'ALL CATEGORIES'}</strong>
          <img className={styles.menuArrow} src={menuArrow} alt='Open menu' />
          <div id='category-menu' className={menuClasses}>
            {categories.map(category => (
              <MenuItems key={category} category={category} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
/* Exports */
export default Timeline
