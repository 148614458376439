/* Packages */
import React, { useContext, useEffect, useState } from 'react'
/* Imported Components */
import { Context } from '../context'
import { Layout } from '../components'
import VerticalImages from '../components/history/verticalImages/verticalImages'
import Timeline from '../components/history/timeline/timeline'
/* Styles */
/* Component */
const History = () => {
  const { clearSelections, setClearSelections } = useContext(Context)
  const [decade, setDecade] = useState()

  useEffect(() => {
    if (clearSelections) {
      setClearSelections(false)
      setDecade(undefined)
    }
  }, [clearSelections])

  return (
    <Layout title='History'>
      {decade === undefined && <VerticalImages decade={decade} setDecade={setDecade} />}
      {decade !== undefined && <Timeline decade={decade} setDecade={setDecade} />}
    </Layout>
  )
}
/* Exports */
export default History
