import React, { useEffect, useState } from 'react'

import styles from './category.module.scss'

const Category = ({ category, setFilterText }) => {
  const [categoryClasses, setCategoryClasses] = useState(styles.category)

  useEffect(() => {
    setTimeout(() => {
      setCategoryClasses(styles.category + ' ' + styles.show)
    }, 500)
  }, [])

  const filterCategory = () => setFilterText(category)

  return <p className={categoryClasses} onClick={filterCategory}>{category}</p>
}

export default Category
