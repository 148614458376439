/* Packages */
import React, { useContext, useEffect, useState } from 'react'
import BackgroundImage from 'gatsby-background-image'
/* Imported Components */
import { Context } from '../../../context'
/* Styles */
import styles from './verticalImages.module.scss'
/* Component */
const VerticalImages = ({ setDecade }) => {
  const { history, windowSize: { device } } = useContext(Context)
  const [verticalImagesClasses, setVerticalImagesClasses] = useState(styles.verticalImages)
  const showTimeline = e => setDecade(parseInt(e.currentTarget.id))

  useEffect(() => {
    setVerticalImagesClasses(styles.verticalImages + `${device === 'phone' ? ' ' + styles.phone : ''}`)
  }, [device])

  const VerticalImage = ({ title, image, i }) => {
    const [verticalImageClasses, setVerticalImageClasses] = useState(styles.verticalImage)
    const [coverClasses, setCoverClasses] = useState(styles.cover)

    useEffect(() => {
      setTimeout(() => {
        setVerticalImageClasses(styles.verticalImage + ' ' + styles.show)
        setTimeout(() => {
          setCoverClasses(styles.cover + ' ' + styles.hide)
          setTimeout(() => {
            setVerticalImageClasses(styles.verticalImage + ' ' + styles.show + ' ' + styles.grayscale)
          }, 250)
        }, 500)
      }, (i + 1) * 100)
    }, [history])

    return (
      <div key={title} className={verticalImageClasses}>
        <BackgroundImage id={i} Tag='div' className={styles.image} fluid={image.fluid} onClick={showTimeline}>
          <div>
            <div className={styles.label}>
              <div className={styles.year}><strong>{title}</strong></div>
              <div className={styles.bar} />
            </div>
            <div className={styles.overlay} />
          </div>
          <div className={coverClasses} />
        </BackgroundImage>
      </div>
    )
  }

  return (
    <section className={verticalImagesClasses}>
      {history.map(({ title, image }, i) => (
        <VerticalImage key={i} title={title} image={image} i={i} />
      ))}
    </section>
  )
}
/* Exports */
export default VerticalImages
